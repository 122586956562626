<template>
  <v-container class="no-bg">
    <v-row>
      <v-col cols="auto">
        <v-chip
          color="primary"
          label
        >
          Primary
        </v-chip>
      </v-col>
    </v-row>
    <v-row
      class="mb-2 ml-2"
      dense
    >
      <v-col cols="1">
        <v-chip
          color="primary lighten-5 black--text"
          label
          small
        >
          lighten-5
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="primary lighten-4 black--text"
          label
          small
        >
          lighten-4
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="primary lighten-3 black--text"
          label
          small
        >
          lighten-3
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="primary lighten-2 black--text"
          label
          small
        >
          lighten-2
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="primary lighten-1 black--text"
          label
          small
        >
          lighten-1
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="primary darken-1"
          label
          small
        >
          darken-1
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="primary darken-2"
          label
          small
        >
          darken-2
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="primary darken-3"
          label
          small
        >
          darken-3
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="primary darken-4"
          label
          small
        >
          darken-4
        </v-chip>
      </v-col>
    </v-row>
    <v-divider class="mx-4" />
    <v-row>
      <v-col cols="auto">
        <v-chip
          color="secondary"
          label
        >
          Secondary
        </v-chip>
      </v-col>
    </v-row>
    <v-row
      class="mb-2 ml-2"
      dense
    >
      <v-col cols="1">
        <v-chip
          color="secondary lighten-5 black--text"
          label
          small
        >
          lighten-5
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="secondary lighten-4 black--text"
          label
          small
        >
          lighten-4
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="secondary lighten-3 black--text"
          label
          small
        >
          lighten-3
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="secondary lighten-2 black--text"
          label
          small
        >
          lighten-2
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="secondary lighten-1 black--text"
          label
          small
        >
          lighten-1
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="secondary darken-1"
          label
          small
        >
          darken-1
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="secondary darken-2"
          label
          small
        >
          darken-2
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="secondary darken-3"
          label
          small
        >
          darken-3
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="secondary darken-4"
          label
          small
        >
          darken-4
        </v-chip>
      </v-col>
    </v-row>
    <v-divider class="mx-4" />
    <v-row>
      <v-col cols="auto">
        <v-chip
          color="accent"
          label
        >
          Accent
        </v-chip>
      </v-col>
    </v-row>
    <v-row
      class="mb-2 ml-2"
      dense
    >
      <v-col cols="1">
        <v-chip
          color="accent lighten-5 black--text"
          label
          small
        >
          lighten-5
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="accent lighten-4 black--text"
          label
          small
        >
          lighten-4
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="accent lighten-3 black--text"
          label
          small
        >
          lighten-3
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="accent lighten-2 black--text"
          label
          small
        >
          lighten-2
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="accent lighten-1 black--text"
          label
          small
        >
          lighten-1
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="accent darken-1"
          label
          small
        >
          darken-1
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="accent darken-2"
          label
          small
        >
          darken-2
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="accent darken-3"
          label
          small
        >
          darken-3
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="accent darken-4"
          label
          small
        >
          darken-4
        </v-chip>
      </v-col>
    </v-row>
    <v-divider class="mx-4" />
    <v-row>
      <v-col cols="auto">
        <v-chip
          color="error"
          label
        >
          Error
        </v-chip>
      </v-col>
    </v-row>
    <v-row
      class="mb-2 ml-2"
      dense
    >
      <v-col cols="1">
        <v-chip
          color="error lighten-5 black--text"
          label
          small
        >
          lighten-5
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="error lighten-4 black--text"
          label
          small
        >
          lighten-4
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="error lighten-3 black--text"
          label
          small
        >
          lighten-3
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="error lighten-2 black--text"
          label
          small
        >
          lighten-2
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="error lighten-1 black--text"
          label
          small
        >
          lighten-1
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="error darken-1"
          label
          small
        >
          darken-1
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="error darken-2"
          label
          small
        >
          darken-2
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="error darken-3"
          label
          small
        >
          darken-3
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="error darken-4"
          label
          small
        >
          darken-4
        </v-chip>
      </v-col>
    </v-row>
    <v-divider class="mx-4" />
    <v-row>
      <v-col cols="auto">
        <v-chip
          color="info"
          label
        >
          Info
        </v-chip>
      </v-col>
    </v-row>
    <v-row
      class="mb-2 ml-2"
      dense
    >
      <v-col cols="1">
        <v-chip
          color="info lighten-5 black--text"
          label
          small
        >
          lighten-5
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="info lighten-4 black--text"
          label
          small
        >
          lighten-4
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="info lighten-3 black--text"
          label
          small
        >
          lighten-3
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="info lighten-2 black--text"
          label
          small
        >
          lighten-2
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="info lighten-1 black--text"
          label
          small
        >
          lighten-1
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="info darken-1"
          label
          small
        >
          darken-1
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="info darken-2"
          label
          small
        >
          darken-2
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="info darken-3"
          label
          small
        >
          darken-3
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="info darken-4"
          label
          small
        >
          darken-4
        </v-chip>
      </v-col>
    </v-row>
    <v-divider class="mx-4" />
    <v-row>
      <v-col cols="auto">
        <v-chip
          color="success"
          label
        >
          Success
        </v-chip>
      </v-col>
    </v-row>
    <v-row
      class="mb-2 ml-2"
      dense
    >
      <v-col cols="1">
        <v-chip
          color="success lighten-5 black--text"
          label
          small
        >
          lighten-5
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="success lighten-4 black--text"
          label
          small
        >
          lighten-4
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="success lighten-3 black--text"
          label
          small
        >
          lighten-3
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="success lighten-2 black--text"
          label
          small
        >
          lighten-2
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="success lighten-1 black--text"
          label
          small
        >
          lighten-1
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="success darken-1"
          label
          small
        >
          darken-1
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="success darken-2"
          label
          small
        >
          darken-2
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="success darken-3"
          label
          small
        >
          darken-3
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="success darken-4"
          label
          small
        >
          darken-4
        </v-chip>
      </v-col>
    </v-row>
    <v-divider class="mx-4" />
    <v-row>
      <v-col cols="auto">
        <v-chip
          color="warning"
          label
        >
          Warning
        </v-chip>
      </v-col>
    </v-row>
    <v-row
      class="mb-2 ml-2"
      dense
    >
      <v-col cols="1">
        <v-chip
          color="warning lighten-5 black--text"
          label
          small
        >
          lighten-5
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="warning lighten-4 black--text"
          label
          small
        >
          lighten-4
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="warning lighten-3 black--text"
          label
          small
        >
          lighten-3
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="warning lighten-2 black--text"
          label
          small
        >
          lighten-2
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="warning lighten-1 black--text"
          label
          small
        >
          lighten-1
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="warning darken-1"
          label
          small
        >
          darken-1
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="warning darken-2"
          label
          small
        >
          darken-2
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="warning darken-3"
          label
          small
        >
          darken-3
        </v-chip>
      </v-col>
      <v-col cols="1">
        <v-chip
          color="warning darken-4"
          label
          small
        >
          darken-4
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
