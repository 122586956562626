var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "no-bg" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-chip", { attrs: { color: "primary", label: "" } }, [
                _vm._v("\n        Primary\n      ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-2 ml-2", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "primary lighten-5 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-5\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "primary lighten-4 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-4\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "primary lighten-3 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-3\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "primary lighten-2 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-2\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "primary lighten-1 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-1\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "primary darken-1", label: "", small: "" } },
                [_vm._v("\n        darken-1\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "primary darken-2", label: "", small: "" } },
                [_vm._v("\n        darken-2\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "primary darken-3", label: "", small: "" } },
                [_vm._v("\n        darken-3\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "primary darken-4", label: "", small: "" } },
                [_vm._v("\n        darken-4\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-4" }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-chip", { attrs: { color: "secondary", label: "" } }, [
                _vm._v("\n        Secondary\n      ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-2 ml-2", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "secondary lighten-5 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-5\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "secondary lighten-4 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-4\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "secondary lighten-3 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-3\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "secondary lighten-2 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-2\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "secondary lighten-1 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-1\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: { color: "secondary darken-1", label: "", small: "" }
                },
                [_vm._v("\n        darken-1\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: { color: "secondary darken-2", label: "", small: "" }
                },
                [_vm._v("\n        darken-2\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: { color: "secondary darken-3", label: "", small: "" }
                },
                [_vm._v("\n        darken-3\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: { color: "secondary darken-4", label: "", small: "" }
                },
                [_vm._v("\n        darken-4\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-4" }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-chip", { attrs: { color: "accent", label: "" } }, [
                _vm._v("\n        Accent\n      ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-2 ml-2", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "accent lighten-5 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-5\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "accent lighten-4 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-4\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "accent lighten-3 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-3\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "accent lighten-2 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-2\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "accent lighten-1 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-1\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "accent darken-1", label: "", small: "" } },
                [_vm._v("\n        darken-1\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "accent darken-2", label: "", small: "" } },
                [_vm._v("\n        darken-2\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "accent darken-3", label: "", small: "" } },
                [_vm._v("\n        darken-3\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "accent darken-4", label: "", small: "" } },
                [_vm._v("\n        darken-4\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-4" }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-chip", { attrs: { color: "error", label: "" } }, [
                _vm._v("\n        Error\n      ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-2 ml-2", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "error lighten-5 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-5\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "error lighten-4 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-4\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "error lighten-3 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-3\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "error lighten-2 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-2\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "error lighten-1 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-1\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "error darken-1", label: "", small: "" } },
                [_vm._v("\n        darken-1\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "error darken-2", label: "", small: "" } },
                [_vm._v("\n        darken-2\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "error darken-3", label: "", small: "" } },
                [_vm._v("\n        darken-3\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "error darken-4", label: "", small: "" } },
                [_vm._v("\n        darken-4\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-4" }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-chip", { attrs: { color: "info", label: "" } }, [
                _vm._v("\n        Info\n      ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-2 ml-2", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "info lighten-5 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-5\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "info lighten-4 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-4\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "info lighten-3 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-3\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "info lighten-2 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-2\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "info lighten-1 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-1\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "info darken-1", label: "", small: "" } },
                [_vm._v("\n        darken-1\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "info darken-2", label: "", small: "" } },
                [_vm._v("\n        darken-2\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "info darken-3", label: "", small: "" } },
                [_vm._v("\n        darken-3\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "info darken-4", label: "", small: "" } },
                [_vm._v("\n        darken-4\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-4" }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-chip", { attrs: { color: "success", label: "" } }, [
                _vm._v("\n        Success\n      ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-2 ml-2", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "success lighten-5 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-5\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "success lighten-4 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-4\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "success lighten-3 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-3\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "success lighten-2 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-2\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "success lighten-1 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-1\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "success darken-1", label: "", small: "" } },
                [_vm._v("\n        darken-1\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "success darken-2", label: "", small: "" } },
                [_vm._v("\n        darken-2\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "success darken-3", label: "", small: "" } },
                [_vm._v("\n        darken-3\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "success darken-4", label: "", small: "" } },
                [_vm._v("\n        darken-4\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-4" }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-chip", { attrs: { color: "warning", label: "" } }, [
                _vm._v("\n        Warning\n      ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-2 ml-2", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "warning lighten-5 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-5\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "warning lighten-4 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-4\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "warning lighten-3 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-3\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "warning lighten-2 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-2\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "warning lighten-1 black--text",
                    label: "",
                    small: ""
                  }
                },
                [_vm._v("\n        lighten-1\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "warning darken-1", label: "", small: "" } },
                [_vm._v("\n        darken-1\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "warning darken-2", label: "", small: "" } },
                [_vm._v("\n        darken-2\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "warning darken-3", label: "", small: "" } },
                [_vm._v("\n        darken-3\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-chip",
                { attrs: { color: "warning darken-4", label: "", small: "" } },
                [_vm._v("\n        darken-4\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }