<template>
  <v-container
    fill-height
    fluid
  >
    <v-row
      align="center"
      justify="center"
      no-gutters
    >
      <v-col class="text-center">
        <span class="d-block">
          Use the left navigation bar to browse your content
        </span>
        <span class="d-block">
          Made with
          <v-icon
            color="red"
            small
          >
            fas fa-heart
          </v-icon>
          by the NurseBrite team
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
};
</script>
