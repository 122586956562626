var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "portal",
        { attrs: { to: "page-title" } },
        [
          _c(
            "v-container",
            { staticClass: "py-0 mx-0" },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-between" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pl-0",
                      attrs: { "align-self": "center", cols: "auto" }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ml-1", attrs: { align: "center" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "title page-title d-inline-block text-truncate"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.department.name) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.schedulePeriods.length > 0
        ? _c(
            "v-card",
            { staticClass: "pa-5" },
            [
              _c("v-card-title", { staticClass: "headline px-0 pt-0" }, [
                _vm._v("\n      Schedules\n    ")
              ]),
              _c("v-select", {
                staticClass: "schedule-picker subtitle-2",
                staticStyle: { width: "250px" },
                attrs: {
                  dense: "",
                  "hide-details": "",
                  items: _vm.schedulePeriods,
                  outlined: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function(ref) {
                        var attrs = ref.attrs
                        var item = ref.item
                        var on = ref.on
                        return [
                          _c(
                            "v-list-item",
                            _vm._g(
                              _vm._b(
                                { staticClass: "schedule-period" },
                                "v-list-item",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.text) +
                                        "\n            "
                                    )
                                  ])
                                ],
                                1
                              ),
                              item.indicator.chipLabel
                                ? _c(
                                    "v-list-item-action",
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          class: [
                                            "font-weight-medium white--text",
                                            item.indicator.color
                                          ],
                                          attrs: { small: "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t(item.indicator.chipLabel)
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3087823116
                ),
                model: {
                  value: _vm.selectedScheduleId,
                  callback: function($$v) {
                    _vm.selectedScheduleId = $$v
                  },
                  expression: "selectedScheduleId"
                }
              }),
              _vm.selectedScheduleItem
                ? [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { staticClass: "pb-0" }, [
                          _vm._v("\n          State\n          "),
                          _vm.autoTransition(_vm.selectedScheduleItem)
                            ? _c("div", { staticClass: "caption" }, [
                                _vm._v(
                                  "\n            Schedule’s state will be changed to "
                                ),
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        _vm.scheduleStates[
                                          _vm.getRealState(
                                            _vm.selectedScheduleItem
                                          )
                                        ].label
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" at "),
                                _c("b", [_vm._v(_vm._s(_vm.nextHour()))]),
                                _vm._v(".\n          ")
                              ])
                            : _vm._e()
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _vm._l(_vm.scheduleStates, function(state, key) {
                          return [
                            state.display
                              ? _c(
                                  "v-col",
                                  { key: key, attrs: { cols: "auto" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "white--text",
                                        attrs: {
                                          color: state.color,
                                          disabled:
                                            _vm.selectedScheduleItem.state ===
                                            key,
                                          width: "200px"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.updateScheduleState(
                                              _vm.selectedScheduleItem.value,
                                              key
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$t(state.label)) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  ]
                : _vm._e(),
              _c(
                "v-overlay",
                {
                  attrs: {
                    absolute: "",
                    opacity: 0.2,
                    value: _vm.savingSchedule
                  }
                },
                [
                  _c(
                    "v-progress-circular",
                    {
                      attrs: {
                        color: "info",
                        indeterminate: "",
                        size: "75",
                        width: "6"
                      }
                    },
                    [_vm._v("\n        Saving\n      ")]
                  )
                ],
                1
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }