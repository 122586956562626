var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center", "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "text-center" }, [
            _c("span", { staticClass: "d-block" }, [
              _vm._v(
                "\n        Use the left navigation bar to browse your content\n      "
              )
            ]),
            _c(
              "span",
              { staticClass: "d-block" },
              [
                _vm._v("\n        Made with\n        "),
                _c("v-icon", { attrs: { color: "red", small: "" } }, [
                  _vm._v("\n          fas fa-heart\n        ")
                ]),
                _vm._v("\n        by the NurseBrite team\n      ")
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }