<template>
  <v-container fluid>
    <portal to="page-title">
      <v-container class="py-0 mx-0">
        <v-row justify="space-between">
          <v-col
            align-self="center"
            class="pl-0"
            cols="auto"
          >
            <v-row
              align="center"
              class="ml-1"
            >
              <span
                class="title page-title d-inline-block text-truncate"
              >
                {{ department.name }}
              </span>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </portal>
    <v-card
      v-if="schedulePeriods.length > 0"
      class="pa-5"
    >
      <v-card-title
        class="headline px-0 pt-0"
      >
        Schedules
      </v-card-title>
      <v-select
        v-model="selectedScheduleId"
        class="schedule-picker subtitle-2"
        dense
        hide-details
        :items="schedulePeriods"
        outlined
        style="width: 250px"
      >
        <template v-slot:item="{ attrs, item, on }">
          <v-list-item
            class="schedule-period"
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="item.indicator.chipLabel">
              <v-chip
                :class="['font-weight-medium white--text', item.indicator.color]"
                small
              >
                {{ $t(item.indicator.chipLabel) }}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-select>
      <template v-if="selectedScheduleItem">
        <v-row>
          <v-col class="pb-0">
            State
            <div
              v-if="autoTransition(selectedScheduleItem)"
              class="caption"
            >
              Schedule’s state will be changed to <b>{{ $t(scheduleStates[getRealState(selectedScheduleItem)].label) }}</b> at <b>{{ nextHour() }}</b>.
            </div>
          </v-col>
        </v-row>
        <v-row>
          <template
            v-for="(state, key) in scheduleStates"
          >
            <v-col
              v-if="state.display"
              :key="key"
              cols="auto"
            >
              <v-btn
                class="white--text"
                :color="state.color"
                :disabled="selectedScheduleItem.state === key"
                width="200px"
                @click="updateScheduleState(selectedScheduleItem.value, key)"
              >
                {{ $t(state.label) }}
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </template>
      <v-overlay
        absolute
        :opacity="0.2"
        :value="savingSchedule"
      >
        <v-progress-circular
          color="info"
          indeterminate
          size="75"
          width="6"
        >
          Saving
        </v-progress-circular>
      </v-overlay>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { showStatus } from '@/plugins/vue-notification';
import { SCHEDULE_STATES, SCHEDULE_STATES_ORDER } from '@/views/scheduling/constants';

export default {
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!(vm.$store.getters['account/isDepartmentManagement']() || vm.$store.getters['account/isOperationsManagement']())) {
        next('/page_not_found');
      }
    });
  },
  data () {
    return {
      savingSchedule: false,
      selectedScheduleId: null
    };
  },
  computed: {
    dateFormatShort () {
      return this.$store.getters['org/getDateFormatShort']();
    },

    dateFormatLong () {
      return this.$store.getters['org/getDateFormatLong']();
    },

    department () {
      return this.$store.getters['org/getActiveDepartment']() || {};
    },
    schedulePeriods () {
      const schedulingPeriods = this.department.schedulingPeriods;
      const periods = [];
      if (schedulingPeriods) {
        for (let i = 0, len = schedulingPeriods.length; i < len; i++) {
          const startFrom = moment(schedulingPeriods[i].startOn);
          const endBy = moment(schedulingPeriods[i].endOn);
          const indicator = this.scheduleStates[schedulingPeriods[i].state];
          let text = '';
          if (endBy.isSame(startFrom, 'year')) {
            text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
          } else {
            // Display year info for both start and end date if the end date is in different year.
            text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
          }
          periods.push({
            indicator,
            text,
            value: schedulingPeriods[i].id,
            ...schedulingPeriods[i]
          });
        }
      }
      return periods;
    },
    scheduleStates () {
      const states = {
        [ SCHEDULE_STATES.INITIAL ]: {
          'color': 'nb-purple',
          'chipLabel': 'labels.scheduleStateInitialAbbr',
          'display': true,
          'label': 'labels.scheduleStateInitial'
        },
        [ SCHEDULE_STATES.SELF_SCHEDULE ]: {
          'color': 'nb-purple',
          'chipLabel': 'labels.scheduleStateSelfScheduleAbbr',
          'display': true,
          'label': 'labels.scheduleStateSelfSchedule'
        },
        [ SCHEDULE_STATES.DRAFT ]: {
          'color': 'nb-orange',
          'chipLabel': 'labels.scheduleStateDraftAbbr',
          'display': true,
          'label': 'labels.scheduleStateDraft'
        },
        [ SCHEDULE_STATES.PENDING_POST_APPROVAL ]: {
          'color': 'error',
          'chipLabel': 'labels.scheduleStatePendingApprovalDirectorAbbr',
          'display': true,
          'label': 'labels.scheduleStatePendingApprovalDirectorPost'
        },
        [ SCHEDULE_STATES.UNDER_NURSE_REVIEW ]: {
          'color': 'info',
          'chipLabel': 'labels.scheduleStateUnderReviewNurseAbbr',
          'display': true,
          'label': 'labels.scheduleStateUnderReviewNurse'
        },
        [ SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL ]: {
          'color': 'error',
          'chipLabel': 'labels.scheduleStatePendingApprovalDirectorAbbr',
          'display': true,
          'label': 'labels.scheduleStatePendingApprovalDirectorPublish'
        },
        [ SCHEDULE_STATES.PUBLISHED ]: {
          'color': 'success',
          'chipLabel': 'labels.scheduleStatePublishedAbbr',
          'display': true,
          'label': 'labels.scheduleStatePublished'
        },
        [ SCHEDULE_STATES.CURRENT ]: {
          'color': 'success',
          'chipLabel': 'labels.scheduleStateCurrentAbbr',
          'display': true,
          'label': 'labels.scheduleStateCurrent'
        },
        [ SCHEDULE_STATES.PAST ]: {
          'color': 'grey darken-1',
          'chipLabel': 'labels.scheduleStatePastAbbr',
          'display': true,
          'label': 'labels.scheduleStatePast'
        }
      };
      return states;
    },
    selectedScheduleItem () {
      for (let i = 0, len = this.schedulePeriods.length; i < len; i++) {
        if (this.schedulePeriods[i].id === this.selectedScheduleId) {
          return this.schedulePeriods[i];
        }
      }
      return null;
    }
  },
  mounted () {
    if (this.schedulePeriods.length > 0) {
      this.selectedScheduleId = this.schedulePeriods[0].value;
    }
  },
  methods: {
    autoTransition (schedule) {
      const currentStateIdx = _.indexOf(SCHEDULE_STATES_ORDER, schedule.state);
      const calculatedStateIdx = _.indexOf(SCHEDULE_STATES_ORDER, this.getRealState(schedule));

      return currentStateIdx < calculatedStateIdx;
    },
    getRealState (schedule) {
      let calculatedState = schedule.state;
      const hospitalSettings = this.$store.state.org.settings.scheduling;
      const nextHourlyCheck = moment().startOf('hour').add(1, 'h');
      const nurseReviewPeriod = hospitalSettings['nurseReviewPeriod'];
      const nurseSelfScheduleLatent = hospitalSettings['nurseSelfSchedule']['latent'];
      const nurseSelfSchedulePeriod = hospitalSettings['nurseSelfSchedule']['period'];
      const postLeadTime = hospitalSettings['postLeadTime'];
      const schedulePeriod = hospitalSettings['period'];

      const startOn = moment(schedule.startOn);
      const endOn = moment(schedule.endOn);
      const publishDueDate = startOn.clone().subtract(postLeadTime - nurseReviewPeriod, 'd');
      const nurseReviewStartDate = startOn.clone().subtract(postLeadTime, 'd');
      const draftStartDate = startOn.clone().subtract(schedulePeriod - nurseSelfScheduleLatent - nurseSelfSchedulePeriod, 'd');
      const selfScheduleStartDate = startOn.clone().subtract(schedulePeriod - nurseSelfScheduleLatent, 'd');
      if (nextHourlyCheck > endOn) {
        calculatedState = SCHEDULE_STATES.PAST;
      } else if (nextHourlyCheck >= startOn) {
        calculatedState = SCHEDULE_STATES.CURRENT;
      } else if (nextHourlyCheck >= publishDueDate) {
        calculatedState = SCHEDULE_STATES.PUBLISHED;
      } else if (nextHourlyCheck >= nurseReviewStartDate) {
        calculatedState = SCHEDULE_STATES.UNDER_NURSE_REVIEW;
      } else if (nextHourlyCheck >= draftStartDate) {
        calculatedState = SCHEDULE_STATES.DRAFT;
      } else if (nextHourlyCheck >= selfScheduleStartDate) {
        calculatedState = SCHEDULE_STATES.SELF_SCHEDULE;
      } else {
        if (hospitalSettings['nurseSelfSchedule']['alwaysOpen']) {
          calculatedState = SCHEDULE_STATES.SELF_SCHEDULE;
        } else {
          calculatedState = SCHEDULE_STATES.INITIAL;
        }
      }

      return calculatedState;
    },
    nextHour () {
      return moment().startOf('hour').add(1, 'h').format('h:mm A');
    },
    updateScheduleState (scheduleId, state) {
      if (!this.savingSchedule) {
        this.savingSchedule = true;
        const payload = {
          id: scheduleId,
          props: {
            state
          }
        };
        this.$store.dispatch('scheduling/updateSchedule', payload).then(() => {
          this.savingSchedule = false;
          showStatus({
            text: this.$t('descriptions.scheduleSaveSuccess')
          });
        }).catch(error => {
          this.savingSchedule = false;
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.scheduleSaveFail'),
            type: 'error',
            data
          });
        });
      }
    }
  }
};
</script>
