var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c("v-col", { attrs: { md: "6", sm: "12" } }, [
        _c(
          "div",
          { staticClass: "mt-10 mx-3 pa-6" },
          [
            _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _c("v-img", {
                  attrs: {
                    contain: "",
                    "max-width": "50%",
                    src: require("@/assets/images/404-penguin.svg")
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "headline mt-5 text-center " }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("headlines.pageNotFound")) +
                  "\n      "
              )
            ]),
            _c("div", {
              staticClass:
                "darken-2 grey--text mt-5 px-5 text--darken-3 text-center ",
              domProps: {
                innerHTML: _vm._s(_vm.$t("descriptions.pageNotFound"))
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }